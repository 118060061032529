<template>
  <div class="profile-page">
    <div class="top-right-overflow-menu ma-1">
      <v-menu v-if="appBarActions.length" right bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon color="white">mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="action in appBarActions"
            :key="action.id"
            @click="action.onClick"
          >
            <v-icon class="pr-2" size="22" v-if="action.icon">{{
              action.icon
            }}</v-icon>
            <v-list-item-title>{{ action.title }}</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <!-- current app version -->
          <p class="ma-0 text-caption mt-2 mx-2 font-weight-medium text-center">
            v{{ endpoints.currentVersion }}
          </p>
        </v-list>
      </v-menu>
    </div>
    <div
      class="background-rectangle"
      :style="{
        background: $vuetify.theme.themes.light.primary,
        height: '150px',
      }"
    >
      <div class="pa-3">
        <h3 class="align-start white--text">
          Hi {{ currentUser.full_name.split(" ")[0] }}!
        </h3>
        <h3 class="align-start white--text">How are you doing today?</h3>
      </div>
      <div class="profile-image-container">
        <v-avatar v-if="currentUser.image" size="130px" class="mb-2">
          <v-img :src="currentUser.image" class="profile-image"></v-img>
        </v-avatar>
      </div>
    </div>
    <div class="profile-details">
      <v-row justify="center">
        <v-col md="5" align="center">
          <h2>{{ currentUser.full_name }}</h2>
          <p class="mb-2">
            {{ currentUserDesignation }} - {{ currentUser.custom_institution_name || currentInstitution.name }}
          </p>

          <v-card class="text-left" :elevation="$vuetify.breakpoint.mdAndDown ? '0' : null">
            <v-list-item
              class="non-focused"
              v-for="(item, index) in profileItems"
              :key="index"
              :href="item.href || null"
              :to="item.to || null"
              @click="() => (item.action ? item.action() : {})"
            >
              <v-list-item-avatar class="align-self-center">
                <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
                <v-avatar v-else-if="item.image">
                  <v-img :src="item.image"></v-img>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-subtitle>{{ item.heading }}</v-list-item-subtitle>
                <v-list-item-title :class="item.valueClasses">{{
                  item.value
                }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon class="align-self-center" v-if="item.appendIcon"
                ><v-icon>{{ item.appendIcon }}</v-icon></v-list-item-icon
              >
            </v-list-item>
          </v-card>

          <v-divider class="mb-4 mt-6"></v-divider>


          <v-expansion-panels
            class="px-2 mb-3"
            v-if="userSubjects && userSubjects.length"
          >
            <v-expansion-panel>
              <v-expansion-panel-header
                >Subjects ({{ userSubjects.length }})</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-list class="text-left pa-0">
                  <div v-for="subject in userSubjects" :key="subject.id">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          subject.name
                        }}</v-list-item-title>
                        <v-list-item-subtitle>
                          <span v-if="subject.room">{{
                            subject.sections
                          }}</span>
                          <span v-else>
                            <span
                              v-for="room in subject.eligible_rooms"
                              :key="room.id"
                              >{{ room.sections }}
                              <span
                                v-if="
                                  subject.eligible_rooms.length > 1 &&
                                  subject.eligible_rooms.indexOf(room) !=
                                    subject.eligible_rooms.length - 1
                                "
                                >,
                              </span>
                            </span>
                          </span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-icon v-if="!subject.room">
                        <v-chip small> Extra </v-chip>
                      </v-list-item-icon>
                    </v-list-item>
                  </div>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <!-- Extra Subjects -->
          <!-- <v-expansion-panels
            class="px-2 mb-3"
            v-if="extraSubjects && extraSubjects.length"
          >
            <v-expansion-panel>
              <v-expansion-panel-header
                >Extra Subjects ({{
                  extraSubjects.length
                }})</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-list class="text-left pa-0">
                  <div v-for="subject in extraSubjects" :key="subject.id">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          subject.name
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels> -->

          <change-password-sheet
            :visible="showChangePasswordSheet"
            @close="showChangePasswordSheet = false"
          />
        </v-col>
      </v-row>
    </div>

    <confirmation-dialog
      title="Are you sure you want to logout?"
      description="This will redirect you back to the login screen"
      :visible="logoutDialog"
      @successCallback="$router.push({ name: 'Logout' })"
      @failureCallback="logoutDialog = false"
    />
    <v-slide-y-transition>
      <v-btn
        style="z-index: 100; bottom: 72px"
        class="extended-fab non-focused"
        color="primary"
        v-if="!isLoading && showFab"
        :href="`https://wa.me/+${currentInstitution.preferences.custom_support_number && currentUser.is_student?
                                 currentInstitution.preferences.custom_support_number: 
                                '919175915234'
                                }?text=Hi! I'm ${currentUser.full_name} from ${currentInstitution.name}. I had an issue with the App...`"
        target="_blank"
        dark
        small
        right
        bottom
        fixed
        fab
      >
        <v-img class="mr-2" width="28px" src="@assets/images/WhatsApp.png" />
        WhatsApp Support
      </v-btn>
    </v-slide-y-transition>

    <ProfileEditDialog 
      :visible="showProfileDetailsDialog"
      @close="showProfileDetailsDialog = false"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Mixins from "@utils/mixins";
// import { Share } from "@capacitor/share";
import moment from "moment";
import ChangePasswordSheet from "@components/auth/ChangePasswordSheet";
import { Browser } from "@capacitor/browser";
import ProfileEditDialog from "@components/dialogs/ProfileEditDialog";

export default {
  name: "Profile",
  mixins: [Mixins.essentials],
  components: {
    ConfirmationDialog: () => import("@components/dialogs/ConfirmationDialog"),
    ChangePasswordSheet,
    ProfileEditDialog
  },
  data() {
    return {
      showProfileDetailsDialog: false,
      dummyDOB: null,
      userCopy: null,
      moment: moment,
      logoutDialog: false,
      showInviteSheet: false,
      visible: false,
      showFab:false, 
      showChangePasswordSheet: false,
      platform: null,
      userDetails: {
        dob: false,
        address: false,
        image: false,
        visible: false,
      },
      uploadedImage: null,
    };
  },
  computed: {
    ...mapGetters([
      "accessToken",
      "isLoading",
      "currentUser",
      "currentInstitution",
      "userSubjects",
    ]),

    profileItems() {
      return [
        {
          heading: "Full Name",
          value: this.currentUser.full_name,
          icon: "mdi-rename-box",
        },
        {
          heading: "Username",
          value: this.currentUser.username,
          icon: "mdi-account",
          valueClasses: "student-details-username",
          action: async () => {
            await this.Helper.copyText(this.student.username);
            this.showSnackbar({
              title: "Username copied to clipboard!",
              type: "success",
            });
          },
          appendIcon: "mdi-content-copy",
        },
        {
          heading: "Phone",
          value: this.currentUser.phone,
          icon: "mdi-phone",
        },
        ...this.currentUser.is_student && this.currentUser.fathers_phone ? [{
          heading: "Father's Phone",
          value: this.currentUser.fathers_phone,
          icon: "mdi-phone",
        }] : [],
        ...this.currentUser.is_student ? [{
          heading: "Classroom",
          value: this.currentUser.room.sections,
          icon: "mdi-school",
        }] : [],
        ...this.currentUser.is_student && this.currentUser.custom_institution_name ? [{
          heading: "Institution",
          value: this.currentUser.custom_institution_name,
          icon: "mdi-bank",
        }]:[],
        ...this.currentUser.is_student && this.currentUser.address ? [{
          heading: "Residential Address",
          value: this.currentUser.address,
          icon: "mdi-map-marker",
        }] : [],
        ...this.currentUser.is_student && this.currentUser.dob ? [{
          heading: "Date of Birth",
          value: moment(this.currentUser.dob).format('Do MMMM YYYY'),
          icon: "mdi-cake",
        }] : [],
        ...this.currentUser.is_student && this.currentUser.blood_group ? [{
          heading: "Blood Group",
          value: this.currentUser.blood_group,
          icon: "mdi-blood-bag",
        }]: [],
        ...this.currentUser.is_student && this.currentUser.parent_profile.father_name ? [{
          heading: "Father's Name",
          value: this.currentUser.parent_profile.father_name,
          image: this.currentUser.parent_profile.father_image,
        }]: [],
        ...this.currentUser.is_student && this.currentUser.parent_profile.mother_name ? [{
          heading: "Mother's Name",
          value: this.currentUser.parent_profile.mother_name,
          image: this.currentUser.parent_profile.mother_image,
        }]: [],
      ];
    },
    appBarActions() {
      var actions = [
        // {
        //   title: "Invite",
        //   // onClick: ,
        //   icon: "mdi-account-plus",
        // },

        {
          title: "Change Password",
          onClick: () => (this.showChangePasswordSheet = true),
          icon: "mdi-lock-reset",
        },
        ...(this.currentUser.is_admin || this.currentUser.is_faculty
          ? [
              {
                title: "Invite Users",
                onClick: async () => {
                  // if (this.platform == "web") {
                    let text = `Download the ${this.endpoints.appTitle} for our institution.\n\nDownload Links:\n\nAndroid: ${this.endpoints.playStoreLink}\n\niOS: ${this.endpoints.appStoreLink}\n\nWeb: ${this.endpoints.webAppLink}`;
                    await this.Helper.copyText(text);
                    this.showSnackbar({
                      title: "Invite message copied to clipboard!",
                      type: "success",
                    });
                  // } else {
                  //   Share.share({
                  //     title: `Check out the ${this.endpoints.appTitle} app`,
                  //     text: `Download the ${this.endpoints.appTitle} for our institution.\n\nDownload Links:\n\nAndroid: ${this.endpoints.playStoreLink}\n\niOS: ${this.endpoints.appStoreLink}\n\nWeb: ${this.endpoints.webAppLink}`,
                  //   });
                  // }
                },
                icon: "fa-sign-in-alt",
              },
            ]
          : []),
        // ...(this.currentUser.is_student && this.currentUser.dob
        //   ? [
        //       {
        //         title: "Edit Birthday",
        //         onClick: this.editBirthday,
        //         icon: "mdi-cake",
        //       },
        //     ]
        //   : []),
        ...(this.currentUser.is_student && this.currentUser.dob && this.currentUser.image && this.currentUser.address
          ? [
              {
                title: "Edit Profile Details",
                onClick: this.editProfileDetails,
                icon: "mdi-account",
              },
            ]
          : []),
        ...this.getCustomLinks(),
        {
          title: "Logout",
          onClick: this.logout,
          icon: "mdi-account-arrow-right",
        },
      ];
      return actions;
    },
    // invitationOptions() {
    //   return [
    //     {
    //       icon: "fa-user-graduate",
    //       title: "Invite Students",
    //       subtitle: "Send an invitation message to your students",
    //       // action: () => (this.createImagePostSheet.visible = true),
    //       action: () => Share.share({
    //           title: 'See cool stuff',
    //           text: '',
    //           url: '',
    //       }),
    //     },
    //     {
    //       icon: "fa-chalkboard-teacher",
    //       title: "Invite Staff",
    //       subtitle: "Send an invitation message to your teachers & faculty",
    //       // action: () => (this.createVideoPostSheet.visible = true),
    //     },
    //     // {
    //     //   icon: "mdi-folder-multiple-image",
    //     //   title: "Folder",
    //     //   subtitle: "Create a folder to better organise your gallery",
    //     //   action: () => (this.editFolderSheet.visible = true),
    //     // },
    //   ];
    // },
    currentUserDesignation() {
      if (this.currentUser.is_admin && this.currentUser.is_faculty)
        return "Admin | Faculty";
      else if (this.currentUser.is_faculty) return "Faculty";
      else if (this.currentUser.is_student)
        return this.currentUser.room.sections;
      else return "";
    }
  },
  methods: {
    ...mapActions(["setLoading", "changeAppBarVisibility"]),
    async logout() {
      this.logoutDialog = true;
    },

    // editBirthday() {
    //   this.visible = true;
    // },

    openLink(link){
      Browser.open({ url: link })
    },

    getCustomLinks(){
      const custom_links = this.currentInstitution.preferences.custom_links
      if(custom_links){
        return Object.keys(custom_links).map(key => {
          return {
              title: key,
              onClick: () => this.openLink(custom_links[key]),
              icon: 'mdi-link'
            }
          }
        )
      }else{
        return []
      }
    },

    editProfileDetails() {
      this.showProfileDetailsDialog = true;
    },

  },
  destroyed() {
    this.changeAppBarVisibility(true);
  },
  async created() {
    var self = this;
    setTimeout(() => (self.showFab = true), 400);
    this.changeAppBarVisibility(false);
    if (this.currentUser.is_admin || this.currentUser.is_faculty) {
      this.platform = await this.Helper.getDevicePlatform();
    }
  },
};
</script>
<style scoped lang="sass">
@import '@assets/styles/global-variables.sass'

.top-right-overflow-menu
  position: fixed
  z-index: 10
  top: 0
  right: 0

// .background-rectangle
//   background: $primary
//   height: 150px

.profile-image-container
  display: flex
  justify-content: center
  width: 100%

.profile-image
  border: 3px solid white

.profile-details
  margin-top: 70px

.top-right-overflow-menu
  margin-top: env(safe-area-inset-top) !important
</style>